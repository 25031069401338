<template>
<div id="semilla-store">
    <v-dialog scrollable v-model="dialog" persistent max-width="900" transition="dialog-top-transition">
        <v-card :loading="loading" :disabled="loading">
            <v-toolbar class="elevation-custom-table">
                <v-spacer></v-spacer>
                <span class="font-weight-bold">Nuevo elemento</span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-file-input accept="image/png, image/jpeg, image/webp" multiple v-model="imagenes" truncate-length="15" ref="imageinput" class="d-none" @change="proccessImage"></v-file-input>
            <v-carousel :show-arrows="false" hide-delimiters v-model="model" height="auto" v-if="imagenes.length > 0">
                <v-carousel-item v-for="imagen in imagenes" :key="imagen.name" contain>
                    <v-img height="300" max-height="300" :src="parseBlob(imagen)" class="grey darken-4" contain>
                    </v-img>
                </v-carousel-item>
            </v-carousel>
            <v-toolbar flat color="grey darken-3" class="px-2" v-if="imagenes.length > 0">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="$refs.imageinput.$refs.input.click()" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-paperclip</v-icon>
                        </v-btn>
                    </template>
                    <span>Seleccionar archivos</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="model--" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-chevron-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Anterior</span>
                </v-tooltip>
                <span class="white--text body-2 font-weight-bold">{{model+1}} / {{imagenes.length}}</span>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="model++" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-btn>
                    </template>
                    <span>Siguiente</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="deleteImage(imagenes[model])" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Remover imagen</span>
                </v-tooltip>
            </v-toolbar>
            <v-img height="160" class="grey darken-3" v-if="imagenes.length == 0">
                <v-card-title class="align-center fill-height" primary-title>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" outlined color="grey darken-2" depressed @click="$refs.imageinput.$refs.input.click()">
                        <v-icon small left color="white">mdi-paperclip</v-icon>
                        <span class="white--text">Seleccionar imagenes</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-title>
            </v-img>
            <v-card-text class="pt-5">
                <v-row>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="semilla.familia" color="primary" persistent-placeholder hide-details>
                            <template #label>
                                Familia<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="semilla.nombreCientifico" label="Nombre científico" color="primary" persistent-placeholder hide-details>
                            <template #label>
                                Nombre científico<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="semilla.nombreComunEspanol" label="Nombre común (Español)" color="primary" persistent-placeholder hide-details>
                            <template #label>
                                Nombre común (Español)<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="semilla.nombreComunMaya" label="Nombre común (Maya)" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="semilla.usos" label="Usos" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="semilla.habitoDeCrecimiento" label="Habito de crecimiento" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="semilla.origenEnLaPeninsulaDeYucatan" label="Origen en la península de Yucatán" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="semilla.distribucionEnLaPeninsulaDeYucatan" label="Distribución en la península de Yucatán" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="semilla.tipoDeVegetacion" label="Tipo de vegetación" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-select v-model="semilla.fruto" :items="months" color="primary" item-text="value" label="Fruto (meses)" persistent-placeholder multiple hide-details></v-select>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-menu ref="menu" :close-on-content-click="false" :return-value.sync="semilla.fechaDeRecoleccion" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :value="formatDate(semilla.fechaDeRecoleccion)" label="Fecha de recolección" persistent-placeholder readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
                            </template>
                            <v-date-picker v-model="semilla.fechaDeRecoleccion" no-title scrollable color="primary">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false" class="text-none">
                                    Cancelar
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(semilla.fechaDeRecoleccion)" class="text-none">
                                    Aceptar
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="semilla.nombreDeElRecolector" label="Nombre de el recolector" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="semilla.claveDeColeccion" label="Clave de colección" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="semilla.publicacionAsociada" label="Publicación asociada" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="semilla.proyecto" label="Proyecto" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="text-none" color="grey darken-2" text @click="$router.push('/semillas')">
                    Cancelar
                </v-btn>
                <v-btn color="green darken-1" class="text-none" text @click="store">
                    Agregar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    name: 'SemillaStore',
    data: () => ({
        loading: false,
        dialog: true,
        model: 0,
        imagenes: [],
        semilla: {
            familia: null,
            nombreCientifico: null,
            nombreComunEspanol: null,
            nombreComunMaya: null,
            usos: null,
            habitoDeCrecimiento: null,
            origenEnLaPeninsulaDeYucatan: null,
            DistribucionEnLaPeninsulaDeYucatan: null,
            tipoDeVegetacion: null,
            fruto: null,
            fechaDeRecoleccion: null,
            nombreDeElRecolector: null,
            claveDeColeccion: null,
            publicacionAsociada: null,
            proyecto: null
        },
        months: [
            { value: 'Enero' },
            { value: 'Febrero' },
            { value: 'Marzo' },
            { value: 'Abril' },
            { value: 'Mayo' },
            { value: 'Junio' },
            { value: 'Julio' },
            { value: 'Agosto' },
            { value: 'Septiembre' },
            { value: 'Octubre' },
            { value: 'Noviembre' },
            { value: 'Diciembre' },
        ]
    }),
    methods: {
        parseBlob(imagen) {
            return URL.createObjectURL(imagen);
        },
        deleteImage(imagen) {
            let index = this.imagenes.findIndex(img => img.name == imagen.name);
            this.imagenes.splice(index, 1);
        },
        proccessImage() {
            this.imagenes.forEach(imagen => {
                if (imagen.type != 'image/png' && imagen.type != 'image/jpg' && imagen.type != 'image/jpeg' && imagen.type != 'image/webp') {
                    let index = this.imagenes.findIndex(img => img.name == imagen.name);
                    this.imagenes.splice(index, 1);
                }
            })
        },
        formatDate(date) {
            if (date != null) {
                const dateArray = date.split('-');
                const dateObject = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
                return dateObject.toLocaleDateString('es-MX');
            }
        },
        async store() {
            this.loading = true;
            const form = new FormData();
            form.append('semilla', JSON.stringify(this.semilla));
            this.imagenes.forEach((imagen) => {
                form.append('files', imagen)
            });
            await this.$axios.post('/api/semilla', form).then(response => {
                if (response.status == 201) {
                    this.$store.commit('semilla/INSERT_SEMILLA', response.data);
                    this.$store.commit('snackbar/SHOW_SNACKBAR', { message: 'Elemento agregado.' });
                    this.$router.push('/semillas');
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            });
        }
    },
    props: {
        height: Number
    }
}
</script>
